// Slider.tsx

import { AnimatePresence, motion } from 'framer-motion'
import React, { useRef } from 'react'
import Icon from '~/components/icons/Icon'

import useCarousel from '~/hooks/useCarousel'
import IconArrowLeftSvgUrl from '~/static/svg/IconArrowLeft.svg'
import IconArrowRightSvgUrl from '~/static/svg/IconArrowRight.svg'

interface SliderProps {
  aspectRatio: 'aspect-454/380' | 'aspect-336/341' | ''
  className?: string
  hiddenImage?: boolean
  onSlideChange?: () => void
  slides: React.ReactNode[]
  maxWidth?: number
}

const Slider: React.FC<SliderProps> = ({
  aspectRatio,
  className = '',
  slides,
  onSlideChange,
  maxWidth,
}) => {
  const carouselContainer = useRef(null)

  const { currentSlide, nextSlide, previousSlide } = useCarousel(
    slides.length,
    carouselContainer,
    4000,
    0,
    true,
    onSlideChange
  )
  const currentSlideElement = slides[currentSlide]

  return (
    <div
      className={
        className + ' relative w-full flex-wrap items-center gap-6 lg:flex'
      }
    >
      <div
        className={`ref lg:px-[90px] relative flex w-full justify-center overflow-hidden`}
        ref={carouselContainer}
      >
        <div
          className="relative w-full"
          style={{ maxWidth }}
        >
          {aspectRatio && <div className={`${aspectRatio} relative w-full`} />}
          <AnimatePresence initial={false}>
            <motion.div
              className="motion"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                width: '100%',
                height: '100%',
                // padding: 'inherit',
                position: 'absolute',
              }}
              key={currentSlide}
              transition={{ duration: 0.5 }}
              initial={{
                opacity: 0,
                position: 'absolute',
              }}
              animate={{
                opacity: 1,
                position: aspectRatio !== '' ? 'absolute' : 'relative',
                // position: 'absolute',
              }}
              exit={{
                opacity: 0,
                position: 'absolute',
              }}
            >
              {currentSlideElement}
            </motion.div>
          </AnimatePresence>
        </div>
      </div>

      {slides.length > 1 && (
        <div className="pointer-events-none mt-6 flex h-full w-full items-center justify-center gap-6 lg:absolute lg:mt-0 lg:justify-between">
          <div className="pointer-events-auto flex h-full items-center">
            <Icon
              className="cursor-pointer"
              onClick={() => previousSlide()}
              src={IconArrowLeftSvgUrl}
              width="67px"
              alt="Previous image"
            />
          </div>

          <div className="pointer-events-auto flex h-full items-center justify-end">
            <Icon
              className="w-[67px] cursor-pointer"
              noStyleDimensions={true}
              onClick={() => nextSlide()}
              src={IconArrowRightSvgUrl}
              alt="Next image"
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default Slider
